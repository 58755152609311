import { http } from '@/ky';
import { httpBuildQuery } from '@/utils';

export default {
  name: 'RjBaseReview',

  render(h) {
    return h('router-view', {
      props: {
        company: this.company,
        survey: this.survey,
        contact: this.contact,
        funnel: this.funnel,
        threePack: this.threePack,
      },
    });
  },

  props: {
    company: {
      type: Object,
      required: true,
    },

    survey: {
      type: Object,
      required: true,
    },

    contact: {
      type: Object,
      required: true,
    },

    funnel: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      threePack: [],
    };
  },

  computed: {
    hasPositiveSentiment() {
      const { sentiment, status, feedback } = this.survey;

      return sentiment === 1 && ['Feedback', 'Completed', 'Review site'].includes(status) && feedback === null;
    },

    canShareReview() {
      const { sentiment, status, feedback } = this.survey;

      return sentiment === 1 && status !== 'Review Site' && feedback === null;
    },

    hasNegativeSentiment() {
      const { sentiment, status } = this.survey;

      return sentiment === 0 && ['Feedback', 'Completed'].includes(status);
    },

    hasFeedback() {
      const { sentiment, status, feedback } = this.survey;

      return sentiment !== null && status === 'Feedback' && feedback !== null;
    },

    isCompleted() {
      const { sentiment, status } = this.survey;

      return sentiment !== null && status !== 'Visited';
    },
  },

  watch: {
    '$route.params.code': {
      handler: 'init',
      immediate: true,
    },
  },

  methods: {
    init() {
      this.guard();
      this.fetch();
    },

    guard() {
      if (this.isCompleted && this.hasPositiveSentiment) {
        return this.redirectTo('positive-review');
      }

      if (this.hasFeedback && this.hasNegativeSentiment) {
        return this.redirectTo('completed-review');
      }

      if (this.hasPositiveSentiment && this.canShareReview) {
        return this.redirectTo('positive-review');
      }

      if (this.hasNegativeSentiment && !this.hasFeedback) {
        return this.redirectTo('negative-review');
      }

      return this.redirectTo('review');
    },

    async fetch() {
      const query = httpBuildQuery({
        filter: JSON.stringify({
          include: ['reviewSite'],
          where: { locationId: this.survey.locationId },
        }),
      });

      const response = await http.get(`locationReviewSites?${query}`).json();
      this.threePack = response.filter(this.getThreePack)
        .map(this.relevantFields)
        .sort(this.sortByPriority);
    },

    getThreePack(site) {
      return site.priority !== null;
    },

    relevantFields(site) {
      return {
        id: site.id,
        priority: site.priority,
        identifier: site.identifier,
        profileUrl: site.profileUrl,
        newReviewUrl: site.newReviewUrl,
        reviewSite: {
          id: site.reviewSite.id,
          logo: `${process.env.VUE_APP_RJ_REVIEW_SITE_LOGOS_URL}/${site.reviewSite.id}/${site.reviewSite.logo}`,
          name: site.reviewSite.name,
        },
      };
    },

    sortByPriority(current, next) {
      return current.priority - next.priority;
    },

    redirectTo(name) {
      if (this.$route.name !== name) {
        this.$router.replace({ name });
      }
    },
  },
};
